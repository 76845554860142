import clsx from 'clsx';
import React from 'react';
import classes from './styles.module.scss';

export default function SKCard({ className, reverse, children, ...rest }) {
  return (
    <div
      className={clsx(
        classes.SKCard,
        reverse && classes.reverse,
        className && className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
