import { useState } from 'react';

export function useToggle(defaultValue) {
  const [isEditing, setIsEditing] = useState(defaultValue || false);
  function toggleEditMode(overrideValue = undefined) {
    setIsEditing(
      typeof overrideValue === 'boolean' ? overrideValue : !isEditing
    );
  }
  return [isEditing, toggleEditMode];
}
