import React from 'react';

export const SIKKA_FAQS = [
  {
    key: 'how-can-i-sign-up-on-chillar-app',
    hi: {
      title: <h4>मैं चिल्लर ऐप पर कैसे साइनअप कर सकता हूं?</h4>,
      desc: (
        <div>
          यह आसान है, साइनअप पर जाएं और अपना फोन नंबर दर्ज करें। आपको ओटीपी के
          साथ एक एसएमएस प्राप्त होगा। चिल्लर ऐप पर ओटीपी दर्ज करें। आप पुरस्कार
          अर्जित करने के लिए तैयार हैं।ऑफ़र का आनंद लें।
        </div>
      ),
    },
    en: {
      title: <h4>How can I sign up on Chillar App?</h4>,
      desc: (
        <div>
          It’s simple, go to the Signup and enter your phone number. You will
          receive a SMS with OTP. Enter the OTP on chillar App. You are ready to
          earn the rewards and enjoy the offers.
        </div>
      ),
    },
  },
  {
    key: 'what-are-the-methods-to-earn-rewards-on-chillar-app',
    hi: {
      title: <h4>चिल्लर ऐप पर रिवॉर्ड कमाने के क्या तरीके हैं?</h4>,
      desc: (
        <div>
          <p>
            चिल्लर पर पुरस्कार अर्जित करने के लिए आप निम्नलिखित कार्य कर सकते
            हैं:
          </p>
          <ul>
            <li>ऐप पर ऑफर का लाभ उठाएं।</li>
            <li>
              अपने मित्र को आमंत्रित करें और आप दोनों पुरस्कार अर्जित करें।
            </li>
            <li>दैनिक चेक-इन करें और अपना दैनिक चेकइन अर्जित करें।</li>
          </ul>
        </div>
      ),
    },
    en: {
      title: <h4>What are the methods to earn rewards on chillar App?</h4>,
      desc: (
        <div>
          <p>You can do the following Tasks for earning rewards on chillar:</p>
          <ul>
            <li>Avail the offers on the App.</li>
            <li>Refer to your Friends and you both earn rewards.</li>
            <li>Daily Check in and claim your daily check-in reward.</li>
          </ul>
        </div>
      ),
    },
  },
  {
    key: 'how-can-i-avail-an-offer',
    hi: {
      title: <h4>मैं ऑफ़र का लाभ कैसे उठा सकता/सकती हूं?</h4>,
      desc: (
        <div>
          ऑफ़र पर क्लिक करें और आप ऑफ़र विवरण तक पहुँच जाते हैं। सभी निर्देशों
          को ध्यान से पढ़ें और सभी निर्देशों को एक-एक करके करें। जैसे ही हमें
          विज्ञापनदाताओं की ओर से पुष्टि मिलेगी, चिल्लर आपके खाते में क्रेडिट कर
          दिया जाएगा।
        </div>
      ),
    },
    en: {
      title: <h4>How can I avail an offer?</h4>,
      desc: (
        <div>
          Click on the offer and you reach the Offer Details. Read all the steps
          carefully and perform all the steps one by one. Chillars will be
          credited to your account as soon as we get confirmation from the
          Advertisers side.
        </div>
      ),
    },
  },
  {
    key: 'when-will-the-Chillars-be-added-to-my-wallet',
    hi: {
      title: <h4>चिल्लर मेरे वॉलेट में कब आएंगे?</h4>,
      desc: (
        <div>
          जैसे ही हमें विज्ञापनदाता की ओर से इस बात की पुष्टि मिलती है कि आपने
          ऑफ़र में उल्लिखित सभी चरणों को पूरा कर लिया है, चिल्लर आपके खाते में
          क्रेडिट कर दिया जाएगा।
        </div>
      ),
    },
    en: {
      title: <h4>When will the Chillars be added to my Wallet?</h4>,
      desc: (
        <div>
          Chillars will be credited to your account as soon as we get
          confirmation from the Advertiser side that you have completed all the
          steps that were mentioned in the offer.
        </div>
      ),
    },
  },
  {
    key: 'how-many-Chillars-make-one-1-rupee',
    hi: {
      title: <h4>1 रुपये में कितने चिल्लर बनते हैं?</h4>,
      desc: <div>रूपांतरण के लिए १० चिल्लर = १ रुपया</div>,
    },
    en: {
      title: <h4>How many Chillars make one 1 rupee?</h4>,
      desc: <div>For conversion 10 Chillars = 1 rupee</div>,
    },
  },
  {
    key: 'what-are-the-ongoing-offers',
    hi: {
      title: <h4>चल रहे ऑफर क्या हैं?</h4>,
      desc: (
        <div>
          यदि आपने ऑफ़र के कुछ चरणों को पूरा कर लिया है और सभी चरणों को पूरा
          नहीं किया है या यदि हम विज्ञापनदाताओं से आपके द्वारा पूरे किए गए चरणों
          की पुष्टि की प्रतीक्षा कर रहे हैं तो एक ऑफ़र चल रहे ऑफ़र में आता है।
        </div>
      ),
    },
    en: {
      title: <h4>What are the ongoing offers?</h4>,
      desc: (
        <div>
          An offer is an ongoing offer if you have completed a few steps of the
          offer and not all the steps or if we are waiting for a confirmation of
          the steps completed by you from advertisers.
        </div>
      ),
    },
  },
  {
    key: 'what-is-an-additional-reward-how-can-i-claim-it',
    hi: {
      title: <h4>एक अतिरिक्त रिवॉर्ड क्या है? मैं इसे कैसे कमा सकता हूं?</h4>,
      desc: (
        <div>
          एक बार जब आप किसी ऑफ़र के लिए दिए गए चरणों को पूरा कर लेते हैं, तो आप
          अतिरिक्त रिवॉर्ड प्राप्त करने के योग्य हो जाते हैं। आप बताए गए दिनों
          के लिए ऑफ़र से ऐप को डाउनलोड करके रख कर इसका लाभ उठा सकते हैं। आप
          रोजाना ऐप खोलकर ऐप को रखने के लिए दैनिक रिवॉर्ड प्राप्त कर सकते हैं और
          अंतिम दिन आप शेष इनाम अर्जित कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>What is an additional reward? How can I claim it?</h4>,
      desc: (
        <div>
          Once you complete the Steps given for an offer, you are eligible to
          avail the additional reward. You can avail it by keeping the app
          downloaded from the offer for the mentioned number of days. You can
          get daily rewards for keeping the app by opening the app daily and on
          the last day you can claim the remaining reward.
        </div>
      ),
    },
  },
  {
    key: 'why-wasnt-i-able-to-claim-my-additiona-reward',
    hi: {
      title: <h4>मैं अपना अतिरिक्त रिवॉर्ड क्यों नहीं अर्जित कर पाया?</h4>,
      desc: (
        <div>
          <p>
            आपका अतिरिक्त रिवॉर्ड निम्नलिखित कारणों से आपके खाते में क्रेडिट
            नहीं किया जाता है:
          </p>

          <ul>
            <li>आपने बताए गए समय से पहले ऐप को अनइंस्टॉल कर दिया।</li>
            <li>आपने रोजाना ऐप नहीं खोला।</li>
            <li>आपने दैनिक आधार पर रिडीम बटन पर क्लिक नहीं किया।</li>
          </ul>
        </div>
      ),
    },
    en: {
      title: <h4>Why wasn’t I able to claim my additional reward?</h4>,
      desc: (
        <div>
          <p>
            Your additional reward is not credited to your account due to the
            following reason:
          </p>

          <ul>
            <li>You uninstalled the app before the time mentioned.</li>
            <li>You did not open the App daily.</li>
            <li>You did not click the redeem button on a daily basis.</li>
          </ul>
        </div>
      ),
    },
  },
  {
    key: 'why-should-i-give-my-phone-number-for-logging-in',
    hi: {
      title: <h4>मुझे लॉग इन करने के लिए अपना फोन नंबर क्यों देना चाहिए?</h4>,
      desc: (
        <div>
          एक फ़ोन नंबर एक उपयोगकर्ता की एक विशिष्ट आईडी है और यह हमें उपयोगकर्ता
          द्वारा एप्लिकेशन पर की जा रही गतिविधि के आधार पर उसे प्रबंधित करने और
          बेहतर ऑफ़र देने में मदद करता है।
        </div>
      ),
    },
    en: {
      title: <h4>Why should I give my phone number for logging in?</h4>,
      desc: (
        <div>
          It’s simple, go to the Signup and enter your phone number. You will
          receive a SMS with OTP. Enter the OTP on chillar App. You are ready to
          earn the rewards and enjoy the offers.
        </div>
      ),
    },
  },
  {
    key: 'is-there-any-other-method-of-logging-in',
    hi: {
      title: <h4>क्या लॉग इन करने का कोई अन्य तरीका है?</h4>,
      desc: (
        <div>
          फिलहाल एक उपयोगकर्ता केवल अपने फोन नंबर और ओटीपी पुष्टि का उपयोग करके
          ही लॉग इन कर सकता है।
        </div>
      ),
    },
    en: {
      title: <h4>Is there any other method of logging in?</h4>,
      desc: (
        <div>
          A phone number is a unique ID of a user and it helps us to manage and
          give better offers to a user based on the activity he is doing on the
          Application.
        </div>
      ),
    },
  },
  {
    key: 'what-are-trending-offers',
    hi: {
      title: <h4>ट्रेंडिंग ऑफ़र क्या हैं?</h4>,
      desc: (
        <div>
          ट्रेंडिंग ऑफ़र ऐसे ऑफ़र हैं जिनके लिए रिवॉर्ड मिलने की संभावना बहुत
          अधिक होती है और कार्य को पूरा करने के चरण बहुत आसान होते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>What are Trending Offers?</h4>,
      desc: (
        <div>
          Trending Offers are the offers for which the chances of getting a
          reward is very high and the steps to complete the task are very easy.
        </div>
      ),
    },
  },
  {
    key: 'what-are-the-highest-paying-offers',
    hi: {
      title: <h4>सर्वाधिक कमाई वाले ऑफ़र क्या हैं?</h4>,
      desc: (
        <div>
          सर्वाधिक कमाई वाले ऑफ़र वे हैं जिनके लिए उपयोगकर्ताओं को दिया जाने
          वाला इनाम सभी ऑफ़र में सबसे अधिक है।
        </div>
      ),
    },
    en: {
      title: <h4>What are the highest paying offers?</h4>,
      desc: (
        <div>
          Highest Paying Offers are the ones for which the reward given to the
          users is highest among all the offers.
        </div>
      ),
    },
  },
  {
    key: 'why-has-my-offer-failed-even-when-i-have-completed-all-the-tasks',
    hi: {
      title: (
        <h4>
          जब मैंने सभी चरणों को पूरा कर लिया है तब भी मेरा ऑफ़र फेल क्यों है?
        </h4>
      ),
      desc: (
        <div>
          <p>किसी ऑफ़र को निम्नलिखित कारणों से फेल घोषित किया जा सकता है:</p>
          <ul>
            <li>
              आपने ऑफ़र समाप्त होने की समयावधि से पहले ऑफ़र को पूरा नहीं किया।
            </li>
            <li>ऑफ़र के लिए सभी चरण पूरे नहीं किए गए थे।</li>
            <li>
              हमें विज्ञापनदाताओं की ओर से ऑफ़र पूरा होने की पुष्टि नहीं मिली।
            </li>
          </ul>
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          Why has my offer failed even when I have completed all the tasks?
        </h4>
      ),
      desc: (
        <div>
          <p>
            An offer may be declared as failed because of the following reasons:
          </p>
          <ul>
            <li>
              You did not complete the offer before the time duration for the
              offer ended.
            </li>
            <li>All the steps were not completed for the offer</li>
            <li>
              We did not get a confirmation for the offer completion from
              advertisers side.
            </li>
          </ul>
        </div>
      ),
    },
  },
  {
    key: 'complete-an-offer-even-after-the-status-for-it-is-Failed',
    hi: {
      title: (
        <h4>
          क्या मैं किसी ऑफ़र की स्थिति फेल होने के बाद भी उसे पूरा कर सकता हूं?
        </h4>
      ),
      desc: (
        <div>
          हाँ, आप ऑफ़र को पूरा कर सकते हैं, भले ही आपके लिए ऑफ़र की स्थिति तब तक
          विफल हो जब तक यह ऐप पर सक्रिय है। यदि ऑफ़र निष्क्रिय है तो आपको कोई
          रिवॉर्ड नहीं मिलेगा।
        </div>
      ),
    },
    en: {
      title: (
        <h4>Can I complete an offer even after the status for it is Failed?</h4>
      ),
      desc: (
        <div>
          Yes, you can complete the offer even if the status of the offer for
          you is Failed as long as it is active on the App. If the offer is
          inactive you won’t get any rewards.
        </div>
      ),
    },
  },
  {
    key: 'completed-all-the-steps-for-my-offer-but-didnt-get-any-rewards',
    hi: {
      title: (
        <h4>
          मैंने अपने ऑफ़र के लिए सभी चरण पूरे कर लिए हैं लेकिन मुझे कोई रिवॉर्ड
          नहीं मिला है। क्या समस्या है?
        </h4>
      ),
      desc: (
        <div>
          यदि आपने किसी ऑफ़र के लिए सभी चरणों को पूरा कर लिया है और आपको कोई
          रिवॉर्ड नहीं मिला है, तो आपके ऑफ़र के साथ ट्रैकिंग समस्या हो सकती है
          और हो सकता है कि हमें विज्ञापनदाताओं की ओर से पुष्टि प्राप्त नहीं हुई
          हो। कृपया हमें अपने फोन नंबर और ऑफ़र के नाम के साथ{' '}
          <a
            href="mailto:chillarappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            chillarappofficial@gmail.com
          </a>{' '}
          पर लिखें और हम आपके लिए समस्या का समाधान करने का प्रयास करेंगे।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          I completed all the steps for my offer but didn’t get any rewards.
          What was the issue?
        </h4>
      ),
      desc: (
        <div>
          If you have completed all the steps for an offer and didn’t get any
          rewards, there might be a tracking issue with your offer and we might
          have not received the confirmation for the advertisers side. Please
          write to us at{' '}
          <a
            href="mailto:chillarappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            chillarappofficial@gmail.com
          </a>{' '}
          with your Phone number and Offer Name and we will try to solve the
          issue for you.
        </div>
      ),
    },
  },
  {
    key: 'locally-verified-status-of-an-offer',
    hi: {
      title: (
        <h4>किसी ऑफ़र की लोकल वेरिफाइड स्थिति से आपका क्या तात्पर्य है?</h4>
      ),
      desc: (
        <div>
          लोकल वेरिफाइड ऑफ़र का अर्थ है कि ऑफ़र के ऐप की स्थापना को हमारी ओर से
          ट्रैक किया गया है और हम विज्ञापनदाताओं से इस बात की पुष्टि की
          प्रतीक्षा कर रहे हैं कि ऐप इंस्टॉल को उनकी तरफ से भी ट्रैक किया गया
          है।
        </div>
      ),
    },
    en: {
      title: <h4>What do you mean by Locally Verified status of an offer?</h4>,
      desc: (
        <div>
          Locally Verified offer means that the installation of the app of the
          offer has been tracked at our end and we are waiting for a
          confirmation from the advertisers that the app install has been
          tracked on his side too.
        </div>
      ),
    },
  },
  {
    key: 'status-of-my-offer-changes-from-locally-verified-to-completed',
    hi: {
      title: (
        <h4>
          मेरे ऑफ़र की स्थिति लोकल वेरिफाइड से कम्प्लीटेड में कब बदलती है?
        </h4>
      ),
      desc: (
        <div>
          एक बार जब हमें विज्ञापनदाताओं से यह पुष्टि मिल जाती है कि आपने ऑफ़र
          विवरण में उल्लिखित सभी चरणों को पूरा कर लिया है, तो ऑफ़र की स्थिति
          लोकल वेरिफाइड से कम्प्लीटेड में बदल जाती है। जैसे ही ऑफ़र की स्थिति
          कम्प्लीटेड हो जाती है, हम आपके चिल्लर वॉलेट में पुरस्कार जोड़ देते
          हैं।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          When does the status of my offer changes from Locally Verified to
          Completed?
        </h4>
      ),
      desc: (
        <div>
          The offer status changes from Locally Verified to Completed once we
          receive confirmation from advertisers that you have completed all the
          steps mentioned in the offer details. As soon as the status of the
          offer changes to completed we add rewards in your chillar wallet.
        </div>
      ),
    },
  },
  {
    key: 'advertisers-to-give-confirmation',
    hi: {
      title: (
        <h4>
          मेरे द्वारा सभी चरणों को पूरा करने के बाद विज्ञापनदाताओं को पुष्टिकरण
          देने में कितना समय लगता है?
        </h4>
      ),
      desc: (
        <div>
          सभी ऑफ़र के लिए पुष्टिकरण रीयलटाइम है और रीयलटाइम में आपके वॉलेट में
          इनाम जोड़ा जाता है। कुछ विशेष मामलों में विज्ञापनदाताओं को पुष्टि देने
          में 24 घंटे तक लग सकते हैं। 24 घंटों के बाद अगर हमें विज्ञापनदाताओं की
          ओर से पुष्टि नहीं मिलती है, तो ऑफ़र की स्थिति फेल हो जाती है।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          How much time does it take for the advertisers to give confirmation
          once I complete all the steps?
        </h4>
      ),
      desc: (
        <div>
          For all the offers the confirmation is realtime and reward is added to
          your wallet in realtime. For some special cases advertisers may take
          upto 24 hours to give the confirmation. After 24 hours if we don’t get
          a confirmation from the advertisers side, the offer status changes to
          failed.
        </div>
      ),
    },
  },
  {
    key: 'how-can-i-refer-my-friends-from-chillar',
    hi: {
      title: <h4>मैं अपने दोस्तों को चिल्लर से कैसे रेफर कर सकता हूं?</h4>,
      desc: (
        <div>
          अपने दोस्तों को रेफ़र करने के लिए, अपनी प्रोफ़ाइल में रेफ़र एंड अर्न
          सेगमेंट पर जाएँ। आपको स्क्रीन के नीचे अपना रेफ़रल कोड मिलेगा जिसे आप
          कॉपी कर सकते हैं और सीधे अपने दोस्तों के साथ शेयर कर सकते हैं या शेयर
          सूची में दिए गए ऐप्स के माध्यम से शेयर कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>How can I Refer my Friends from chillar?</h4>,
      desc: (
        <div>
          To refer your friends, go to the Refer and Earn segment in your
          profile. You will find your Referral code at the bottom of the screen
          which you can copy and share directly with your friends or share it
          via the apps given in the share list.
        </div>
      ),
    },
  },
  {
    key: 'when-will-i-get-the-referral-reward',
    hi: {
      title: (
        <h4>
          मेरे द्वारा रेफर किए गए दोस्तों के लिए मुझे रेफ़रल रिवॉर्ड कब मिलेगा?
        </h4>
      ),
      desc: (
        <div>
          साइन अप करते समय आपके द्वारा रेफर मित्र को आपका रेफ़रल कोड दर्ज करना
          होगा। एक बार जब उसने साइन इन किया तो उसे तीन ऑफर का लाभ उठाना होगा।
          जैसे ही वह अपने तीन ऑफर को पूरा करेगा, आपके दोनों के चिल्लर वॉलेट में
          रिवॉर्ड जुड़ जाएंगे।
        </div>
      ),
    },
    en: {
      title: (
        <h4>When will I get the referral reward for the friends I referred?</h4>
      ),
      desc: (
        <div>
          The Friend you referred has to enter your referral code while Sign Up.
          Once he Signed in he has to avail three offers. As soon as he
          completes his three offers, rewards will be added in both of your
          chillar Wallets.
        </div>
      ),
    },
  },
  {
    key: 'what-are-the-rewards-me-and-my-friend',
    hi: {
      title: (
        <h4>
          अगर मैं अपने दोस्त को रेफर कर दूं तो मुझे और मेरे दोस्त को कितना इनाम
          मिलेगा?
        </h4>
      ),
      desc: (
        <div>
          यदि आप अपने मित्र को रेफर करते हैं और वह तीन प्रस्तावों को पूरा करता
          है, तो आपको अपने खाते में 50 चिल्लर मिलते हैं जबकि आपके मित्र को उसके
          खाते में 20 चिल्लर मिलते हैं।
        </div>
      ),
    },
    en: {
      title: (
        <h4>What are the rewards me and my friend will get if I refer him?</h4>
      ),
      desc: (
        <div>
          If your refer your friend and he completes three offers, you get 50
          Chillars in your account while your friend gets 20 Chillars in his
          account.
        </div>
      ),
    },
  },
  {
    key: 'share-my-referral-code-with-my-friend',
    hi: {
      title: (
        <h4>मैं अपने रेफ़रल कोड को अपने मित्र के साथ कैसे शेयर कर सकता हूँ?</h4>
      ),
      desc: (
        <div>
          आप सूची में दिए गए किसी भी चैनल के माध्यम से रेफरल कोड शेयर कर सकते
          हैं या फिर आप रेफरल कोड की प्रतिलिपि बना सकते हैं और इसे अपनी पसंद के
          चैनल के माध्यम से शेयर कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>How can I share my referral code with my friend?</h4>,
      desc: (
        <div>
          You can share referral code through any channel given in the list or
          else you can copy the referral code and share it through the channel
          of your preference.
        </div>
      ),
    },
  },
  {
    key: 'how-can-i-go-to-my-wallet',
    hi: {
      title: <h4>मैं अपने वॉलेट में कैसे जा सकता हूं?</h4>,
      desc: (
        <div>
          आप ऐप में सबसे ऊपर वॉलेट आइकन पर क्लिक करके वॉलेट में जा सकते हैं।
          प्रदर्शित राशि वह कुल राशि है जो आपने चिल्लर के माध्यम से ऐप पर अर्जित
          की है।
        </div>
      ),
    },
    en: {
      title: <h4>How can I go to my wallet?</h4>,
      desc: (
        <div>
          You can go to the wallet by clicking on the Wallet Icon at the top of
          the App. The amount displayed is the total amount you have earned on
          the App via chillar.
        </div>
      ),
    },
  },
  {
    key: 'how-can-i-see-my-earning-history',
    hi: {
      title: <h4>मैं अपनी अर्निंग हिस्ट्री कैसे देख सकता हूँ?</h4>,
      desc: (
        <div>
          अपनी अर्निंग हिस्ट्री देखने के लिए वॉलेट में जाएं और अर्निंग हिस्ट्री
          टैब पर क्लिक करें। वहां आप अपनी सारी कमाई देख सकते हैं जो आपने कार्य
          सौदे ऐप पर की है।
        </div>
      ),
    },
    en: {
      title: <h4>How can I see my earning history?</h4>,
      desc: (
        <div>
          To check your earning history, go to the wallet and click on the
          Earning History tab. There you can see all your earnings that you have
          done on chillar App.
        </div>
      ),
    },
  },
  {
    key: 'minimum-amount-i-can-transfer ',
    hi: {
      title: <h4>चिल्लर ऐप से मैं कितनी न्यूनतम राशि ट्रांसफर कर सकता हूं?</h4>,
      desc: (
        <div>
          पहले ट्रांजेक्शन के लिए न्यूनतम राशि जिसे आप कम से कम रु. 5. दूसरे
          ट्रांजेक्शन के बाद से हस्तांतरित की जा सकने वाली न्यूनतम राशि रु. 30.
        </div>
      ),
    },
    en: {
      title: (
        <h4>What is the minimum amount I can transfer from chillar App?</h4>
      ),
      desc: (
        <div>
          For the first transaction the minimum amount that you can transfer is
          as low as Rs. 5. From the second transaction onwards the minimum
          amount that can be transferred is Rs. 30.
        </div>
      ),
    },
  },
  {
    key: 'channels-to-transfer-my-money',
    hi: {
      title: (
        <h4>चिल्लर ऐप से मेरे पैसे ट्रांसफर करने के लिए कौन से चैनल हैं?</h4>
      ),
      desc: (
        <div>
          आप अपना पैसा अपने पेटीएम वॉलेट में ट्रांसफर कर सकते हैं या यूपीआई आईडी
          का उपयोग करके सीधे अपने बैंक में राशि ट्रांसफर कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: (
        <h4>What are the channels to transfer my money from chillar App?</h4>
      ),
      desc: (
        <div>
          You can transfer your money to your Paytm Wallet or you can directly
          transfer the amount to your bank using the UPI ID.
        </div>
      ),
    },
  },
  {
    key: 'how-much-time-will-it-take-to-transfer-money',
    hi: {
      title: (
        <h4>
          चिल्लर से मेरे बैंक खाते में पैसे ट्रांसफर करने में कितना समय लगेगा?
        </h4>
      ),
      desc: (
        <div>
          आमतौर पर अनुरोध किए जाने के कुछ सेकंड के भीतर स्थानांतरण हो जाता है,
          लेकिन परिस्थितियों के आधार पर बैंक को उनकी ओर से भुगतान संसाधित करने
          में 72 घंटे तक का समय लग सकता है।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          How much time will it take to transfer money from chillar to my Bank
          account?
        </h4>
      ),
      desc: (
        <div>
          Usually the transfer takes place within seconds of the request made
          but the bank may take upto 72 hours to process the payment from their
          side depending on the circumstances.
        </div>
      ),
    },
  },
  {
    key: 'money-is-not-getting-transferred',
    hi: {
      title: (
        <h4>
          मेरे बैंक खाते में मेरा पैसा ट्रांसफर नहीं होने के कुछ कारण क्या हैं?
        </h4>
      ),
      desc: (
        <div>
          <p>
            आपका पैसा आपके बैंक खाते में स्थानांतरित नहीं होने के निम्न कारण
            हैं:
          </p>
          <ul>
            <li>पेटीएम पर आपका केवाईसी पूरा नहीं हुआ है।</li>
            <li>
              आपका यूपीआई आईडी उस पेटीएम खाते से नहीं जुड़ा है जिसका इस्तेमाल आप
              रकम ट्रांसफर करने के लिए कर रहे हैं।
            </li>
          </ul>
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          What are some of the reasons my money is not getting transferred to my
          bank account?
        </h4>
      ),
      desc: (
        <div>
          <p>
            The reasons why your money is not getting transferred to your bank
            account are:
          </p>
          <ul>
            <li>Your KYC is not complete on Paytm.</li>
            <li>
              Your UPI ID is not connected to the Paytm Account you are using to
              transfer the amount.{' '}
            </li>
          </ul>
        </div>
      ),
    },
  },
  {
    key: 'unable-to-transfer-the-money',
    hi: {
      title: <h4>मुझे पैसे ट्रांसफर करने से प्रतिबंधित क्यों किया गया?</h4>,
      desc: (
        <div>
          आपको चिल्लर से पैसे ट्रांसफर करने पर प्रतिबंध लगा दिया गया है क्योंकि
          हो सकता है कि हमें आपके चिल्लर खाते में कुछ असामान्य गतिविधि का पता
          चला हो। अगर आपको लगता है कि आपको गलत तरीके से प्रतिबंधित किया गया है,
          तो हमें{' '}
          <a
            href="mailto:chillarappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            chillarappofficial@gmail.com
          </a>{' '}
          पर अपने फोन नंबर के साथ अपनी समस्या का उल्लेख करते हुए लिखें।
        </div>
      ),
    },
    en: {
      title: (
        <h4>
          All my requirements for transferring money are completed and still I
          am unable to transfer the money. Why?
        </h4>
      ),
      desc: (
        <div>
          There might be some issue from the Bank or Paytm. Try transferring the
          money after 24 hours. If the problem still persists write to us at
          <a
            href="mailto:chillarappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            chillarappofficial@gmail.com
          </a>{' '}
          mentioning your problem along with your Phone Number.
        </div>
      ),
    },
  },
  {
    key: 'why-was-i-banned-from-transferring-money',
    hi: {
      title: (
        <h4>
          पैसे ट्रांसफर करने की मेरी सभी आवश्यकताएं पूरी हो चुकी हैं और फिर भी
          मैं पैसे ट्रांसफर करने में असमर्थ हूं। क्यों?
        </h4>
      ),
      desc: (
        <div>
          बैंक या पेटीएम से कुछ समस्या हो सकती है। 24 घंटे के बाद पैसे ट्रांसफर
          करने की कोशिश करें। अगर फिर भी समस्या बनी रहती है तो हमें{' '}
          <a
            href="mailto:chillarappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            chillarappofficial@gmail.com
          </a>{' '}
          पर लिखें और अपने फोन नंबर के साथ अपनी समस्या का उल्लेख करें।
        </div>
      ),
    },
    en: {
      title: <h4>Why was I banned from transferring money?</h4>,
      desc: (
        <div>
          You get banned from transferring money from chillar because we might
          have detected some unusual activity on your chillar account. If you
          think that you were incorrectly banned, write us at{' '}
          <a
            href="mailto:chillarappofficial@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            chillarappofficial@gmail.com
          </a>{' '}
          mentioning your problem along with your Phone Number.
        </div>
      ),
    },
  },
  {
    key: 'what-is-daily-checkin-how-can-i-avail-it',
    hi: {
      title: <h4>डेली चेकइन क्या है? मैं इसका लाभ कैसे उठा सकता हूं?</h4>,
      desc: (
        <div>
          डेली चेकइन उस उपयोगकर्ता के लिए एक इनाम प्रणाली है जो दैनिक आधार पर
          हमारे ऐप का उपयोग करता है। यह एक स्ट्रीक आधारित इनाम है जो स्ट्रीक की
          निरंतरता के साथ बढ़ता है। आप गिफ्ट आइकन पर क्लिक करके होम स्क्रीन से
          इसको अर्जित कर सकते हैं।
        </div>
      ),
    },
    en: {
      title: <h4>What is Daily Checkin? How can I avail it?</h4>,
      desc: (
        <div>
          Daily Checkin is a reward system for the user who use our app on a
          daily basis. It is a streak based reward which increases with the
          continuation of streak. You can claim it from the home screen by
          clicking on the gift icon.
        </div>
      ),
    },
  },
];
